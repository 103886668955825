<template>
  <div class="reg">
    <div class="reg-header">
      <topNav></topNav>
    </div>
    <div class="reg-main">
      <div class="m-steps">
        <Steps :current="curIndex">
          <Step title="验证手机"></Step>
          <Step title="基本信息"></Step>
          <Step title="完成注册"></Step>
        </Steps>
      </div>

      <div class="admin-add" v-if="phone">
        <div class="admin-add-content">
          <Form ref="formModel" :model="formModel" class="F" :label-width="260">
            <template v-for="(item, index) in formData">
              <!-- 单行文本 -->
              <FormItem v-if="item.tagType === 0 && item.label !== '手机' && item.label !== '密码'" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  message: `${item.label}验证错误`,
                  trigger: 'blur',
                }">
                <Input type="text" v-model="formModel.items[index]['model' + index]" :placeholder="item.attributes.placeholder || '请输入'" style="width: 300px"> </Input>
              </FormItem>
              <FormItem v-if="item.tagType === 0 && item.label === '手机'" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  message: `${item.label}验证错误`,
                  trigger: 'blur',
                }">
                <Input type="text" v-model="formModel.items[index]['model' + index]" :placeholder="item.attributes.placeholder || '请输入'" style="width: 300px" disabled> </Input>
              </FormItem>
              <!-- <FormItem
                v-if="
                  item.tagType === 0 && item.label === '密码' && user.userId
                "
                :key="index"
                :label="item.label"
                :ref="'model' + index"
                :prop="'items.' + index + '.model' + index"
              >
                <Input
                  type="password"
                  value="******"
                  :placeholder="item.attributes.placeholder || '请输入'"
                  style="width: 300px"
                  disabled
                >
                </Input>
              </FormItem> -->
              <FormItem v-if="item.tagType === 0 && item.label === '密码' && !user.userId" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  message: `${item.label}验证错误`,
                  trigger: 'blur',
                }">
                <Input type="text" v-model="formModel.items[index]['model' + index]" :placeholder="item.attributes.placeholder || '请输入'" style="width: 300px"> </Input>
              </FormItem>
              <!-- 单行文本 -->

              <!-- 多行文本 -->
              <FormItem v-if="item.tagType === 1" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  message: `${item.label}验证错误`,
                  trigger: 'blur',
                }">
                <Input type="textarea" :rows="4" v-model="formModel.items[index]['model' + index]" :placeholder="item.attributes.placeholder || '请输入'" style="width: 300px"> </Input>
              </FormItem>
              <!-- 多行文本 -->

              <!-- 数字输入框 -->
              <FormItem v-if="item.tagType === 2" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="[
                  {
                    required: item.attributes.checkOptions.require,
                    type: 'number',
                    message: `${item.label}验证错误`,
                    trigger: 'blur',
                  },
                ]">
                <InputNumber :max="99999" :min="0" style="width: 300px" v-model="formModel.items[index]['model' + index]"> </InputNumber>
              </FormItem>
              <!-- 数字输入框 -->

              <!-- 下拉选择框 -->
              <FormItem v-if="item.tagType === 3" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  message: `${item.label}验证错误`,
                  trigger: 'change',
                }">
                <Select v-model="formModel.items[index]['model' + index]" style="width: 300px">
                  <Option v-for="val in item.columns" :value="val" :key="val">{{ val }}</Option>
                </Select>
              </FormItem>
              <!-- 下拉选择框 -->

              <!-- 单选框 -->
              <FormItem v-if="item.tagType === 4" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  message: `${item.label}验证错误`,
                  trigger: 'change',
                }">
                <RadioGroup v-model="formModel.items[index]['model' + index]" style="width: 300px">
                  <Radio v-for="opt in item.attributes.options" :label="opt.value" :key="opt.value">{{ opt.value }}</Radio>
                </RadioGroup>
              </FormItem>
              <!-- 单选框 -->

              <!-- 多选框 -->
              <FormItem v-if="item.tagType === 5" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  type: 'array',
                  message: `${item.label}验证错误`,
                  trigger: 'change',
                }">
                <CheckboxGroup v-model="formModel.items[index]['model' + index]" style="width: 300px">
                  <Checkbox v-for="opt in item.attributes.options" :label="opt.value" :key="opt.value">{{ opt.value }}</Checkbox>
                </CheckboxGroup>
              </FormItem>
              <!-- 多选框 -->

              <!-- 日期框 -->
              <FormItem v-if="item.tagType === 6" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  type: 'date',
                  message: `${item.label}验证错误`,
                  trigger: 'change',
                }">
                <DatePicker type="date" style="width: 300px" :placeholder="item.attributes.placeholder || '请选择日期'" v-model="formModel.items[index]['model' + index]"> </DatePicker>
              </FormItem>
              <!-- 日期框 -->

              <!-- 日期区间 -->
              <template v-if="item.tagType === 7">
                <FormItem :key="'start' + index" :label="item.childrens[0].label" :ref="'model' + index" :prop="'items.' + index + '.model' + index + '.start'" :rules="{
                    required: item.attributes.checkOptions.require,
                    type: 'date',
                    message: `${item.label}验证错误`,
                    trigger: 'change',
                  }">
                  <DatePicker type="date" style="width: 300px" :placeholder="item.attributes.placeholder || '请选择日期'" v-model="formModel.items[index]['model' + index].start"> </DatePicker>
                </FormItem>
                <FormItem :key="'end' + index" :label="item.childrens[1].label" :ref="'model' + index" :prop="'items.' + index + '.model' + index + '.end'" :rules="{
                    required: item.attributes.checkOptions.require,
                    type: 'date',
                    message: `${item.label}验证错误`,
                    trigger: 'change',
                  }">
                  <DatePicker type="date" style="width: 300px" :placeholder="item.attributes.placeholder || '请选择日期'" v-model="formModel.items[index]['model' + index].end"> </DatePicker>
                </FormItem>
              </template>
              <!-- 日期区间 -->

              <!-- 说明文字 -->
              <FormItem :key="index" v-if="item.tagType === 10" :label-width="260">
                <p class="tipInfo" style="width: 300px">
                  {{ item.attributes.value }}
                </p>
              </FormItem>
              <!-- 说明文字 -->

              <!-- 套件 -->
              <!-- 城市 -->
              <FormItem :key="index" :label="item.label" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  type: 'array',
                  message: `${item.label}验证错误`,
                  trigger: 'change',
                }" v-if="item.tagType === 11 && item.label === '城市'" :label-width="260">
                <Cascader :data="areaList" v-model="formModel.items[index]['model' + index]" style="width: 300px"></Cascader>
              </FormItem>
              <!-- 城市 -->

              <!-- 感兴趣类别 -->
              <FormItem :key="index" :label="item.label" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  type: 'array',
                  message: `${item.label}验证错误`,
                  trigger: 'change',
                }" v-if="item.tagType === 11 && item.label === '感兴趣的类别'" :label-width="260">
                <Row v-for="(item, idx) in formModel.items[index]['model' + index]" :key="cateKey + '' + idx" style="margin-bottom: 5px">
                  <Col span="12">
                  <Cascader v-model="formModel.items[index]['model' + index][idx]" filterable :data="categoryList" :key="'categoryArr' + idx" :ref="'categoryArr' + idx" @on-change="changeCategoryArr('items.' + index + '.model' + index)"></Cascader>
                  </Col>
                  <Col span="2" style="text-align: center">
                  <Icon type="ios-add-circle-outline" v-if="idx === 0" @click="addCategoryArrr(index)" style="color: #2d8cf0; font-size: 20px; cursor: pointer" />
                  <Icon type="ios-remove-circle-outline" v-else style="color: red; font-size: 20px; cursor: pointer" @click="delCategoryArrr(index, idx)" />
                  </Col>
                </Row>
              </FormItem>
              <!-- 感兴趣类别 -->

              <!-- 展馆选择 -->
              <FormItem :key="index" :label="item.label" :rules="{
                  required: item.attributes.checkOptions.require,
                  message: `${item.label}验证错误`,
                  trigger: 'change',
                }" v-if="item.tagType === 11 && item.label === '展馆选择'" :label-width="260">
                <Select v-model="formModel.items[index]['model' + index]" style="width: 300px">
                  <Option v-for="val in hallList" :value="val" :key="val">{{ val }}</Option>
                </Select>
              </FormItem>
              <!-- 展馆选择 -->
              <!-- 套件 -->
            </template>

            <FormItem :label-width="260">
              <Button type="primary" @click="handleSubmit('formModel')" size="large" style="width: 300px" class="btn">下一步</Button>
            </FormItem>
          </Form>
        </div>
      </div>

      <!-- 没有手机号 -->
      <div class="admin-add" v-else>
        <div class="admin-add-content">
          <Form ref="formModel" :model="formModel" class="F" :label-width="260">
            <template v-for="(item, index) in formData">
              <!-- 单行文本 -->
              <FormItem v-if="item.tagType === 0 && item.label !== '手机' && item.label !== '密码'" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  message: `${item.label}验证错误`,
                  trigger: 'blur',
                }">
                <Input type="text" v-model="formModel.items[index]['model' + index]" :placeholder="item.attributes.placeholder || '请输入'" style="width: 300px"> </Input>
              </FormItem>
              <FormItem v-if="item.tagType === 0 && item.label === '手机'" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  message: `${item.label}验证错误`,
                  trigger: 'blur',
                }">
                <div>
                  <Input type="text" v-model="formModel.items[index]['model' + index]" :placeholder="item.attributes.placeholder || '请输入'" style="width: 300px"> </Input>
                </div>
                <div class="service-code">
                  <Input type="text" v-model="numCode" :placeholder="'请输入手机验证码'" style="width: 300px"> </Input>
                  <Button type="text" v-if="showCode" @click="getCaptcha(formModel.items[index]['model' + index])" class="code-btn1">发送验证码</Button>
                  <Button type="text" v-else disabled class="code-btn2" style="color: #ccc">{{ count }}s后重新获取</Button>
                </div>
              </FormItem>
              <!-- <FormItem
                v-if="
                  item.tagType === 0 && item.label === '密码' && user.userId
                "
                :key="index"
                :label="item.label"
                :ref="'model' + index"
                :prop="'items.' + index + '.model' + index"
              >
                <Input
                  type="password"
                  value="******"
                  :placeholder="item.attributes.placeholder || '请输入'"
                  style="width: 300px"
                  disabled
                >
                </Input>
              </FormItem> -->
              <FormItem v-if="item.tagType === 0 && item.label === '密码' && !user.userId" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  message: `${item.label}验证错误`,
                  trigger: 'blur',
                }">
                <Input type="text" v-model="formModel.items[index]['model' + index]" :placeholder="item.attributes.placeholder || '请输入'" style="width: 300px"> </Input>
              </FormItem>
              <!-- 单行文本 -->

              <!-- 多行文本 -->
              <FormItem v-if="item.tagType === 1" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  message: `${item.label}验证错误`,
                  trigger: 'blur',
                }">
                <Input type="textarea" :rows="4" v-model="formModel.items[index]['model' + index]" :placeholder="item.attributes.placeholder || '请输入'" style="width: 300px"> </Input>
              </FormItem>
              <!-- 多行文本 -->

              <!-- 数字输入框 -->
              <FormItem v-if="item.tagType === 2" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="[
                  {
                    required: item.attributes.checkOptions.require,
                    type: 'number',
                    message: `${item.label}验证错误`,
                    trigger: 'blur',
                  },
                ]">
                <InputNumber :max="99999" :min="0" style="width: 300px" v-model="formModel.items[index]['model' + index]"> </InputNumber>
              </FormItem>
              <!-- 数字输入框 -->

              <!-- 下拉选择框 -->
              <FormItem v-if="item.tagType === 3" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  message: `${item.label}验证错误`,
                  trigger: 'change',
                }">
                <Select v-model="formModel.items[index]['model' + index]" style="width: 300px">
                  <Option v-for="val in item.columns" :value="val" :key="val">{{ val }}</Option>
                </Select>
              </FormItem>
              <!-- 下拉选择框 -->

              <!-- 单选框 -->
              <FormItem v-if="item.tagType === 4" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  message: `${item.label}验证错误`,
                  trigger: 'change',
                }">
                <RadioGroup v-model="formModel.items[index]['model' + index]" style="width: 300px">
                  <Radio v-for="opt in item.attributes.options" :label="opt.value" :key="opt.value">{{ opt.value }}</Radio>
                </RadioGroup>
              </FormItem>
              <!-- 单选框 -->

              <!-- 多选框 -->
              <FormItem v-if="item.tagType === 5" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  type: 'array',
                  message: `${item.label}验证错误`,
                  trigger: 'change',
                }">
                <CheckboxGroup v-model="formModel.items[index]['model' + index]" style="width: 300px">
                  <Checkbox v-for="opt in item.attributes.options" :label="opt.value" :key="opt.value">{{ opt.value }}</Checkbox>
                </CheckboxGroup>
              </FormItem>
              <!-- 多选框 -->

              <!-- 日期框 -->
              <FormItem v-if="item.tagType === 6" :key="index" :label="item.label" :ref="'model' + index" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  type: 'date',
                  message: `${item.label}验证错误`,
                  trigger: 'change',
                }">
                <DatePicker type="date" style="width: 300px" :placeholder="item.attributes.placeholder || '请选择日期'" v-model="formModel.items[index]['model' + index]"> </DatePicker>
              </FormItem>
              <!-- 日期框 -->

              <!-- 日期区间 -->
              <template v-if="item.tagType === 7">
                <FormItem :key="'start' + index" :label="item.childrens[0].label" :ref="'model' + index" :prop="'items.' + index + '.model' + index + '.start'" :rules="{
                    required: item.attributes.checkOptions.require,
                    type: 'date',
                    message: `${item.label}验证错误`,
                    trigger: 'change',
                  }">
                  <DatePicker type="date" style="width: 300px" :placeholder="item.attributes.placeholder || '请选择日期'" v-model="formModel.items[index]['model' + index].start"> </DatePicker>
                </FormItem>
                <FormItem :key="'end' + index" :label="item.childrens[1].label" :ref="'model' + index" :prop="'items.' + index + '.model' + index + '.end'" :rules="{
                    required: item.attributes.checkOptions.require,
                    type: 'date',
                    message: `${item.label}验证错误`,
                    trigger: 'change',
                  }">
                  <DatePicker type="date" style="width: 300px" :placeholder="item.attributes.placeholder || '请选择日期'" v-model="formModel.items[index]['model' + index].end"> </DatePicker>
                </FormItem>
              </template>
              <!-- 日期区间 -->

              <!-- 说明文字 -->
              <FormItem :key="index" v-if="item.tagType === 10" :label-width="260">
                <p class="tipInfo" style="width: 300px">
                  {{ item.attributes.value }}
                </p>
              </FormItem>
              <!-- 说明文字 -->

              <!-- 套件 -->
              <!-- 城市 -->
              <FormItem :key="index" :label="item.label" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  type: 'array',
                  message: `${item.label}验证错误`,
                  trigger: 'change',
                }" v-if="item.tagType === 11 && item.label === '城市'" :label-width="260">
                <Cascader :data="areaList" v-model="formModel.items[index]['model' + index]" style="width: 300px"></Cascader>
              </FormItem>
              <!-- 城市 -->

              <!-- 感兴趣类别 -->
              <FormItem :key="index" :label="item.label" :prop="'items.' + index + '.model' + index" :rules="{
                  required: item.attributes.checkOptions.require,
                  type: 'array',
                  message: `${item.label}验证错误`,
                  trigger: 'change',
                }" v-if="item.tagType === 11 && item.label === '感兴趣的类别'" :label-width="260">
                <Row v-for="(item, idx) in formModel.items[index]['model' + index]" :key="cateKey + '' + idx" style="margin-bottom: 5px">
                  <Col span="12">
                  <Cascader v-model="formModel.items[index]['model' + index][idx]" filterable :data="categoryList" :key="'categoryArr' + idx" :ref="'categoryArr' + idx" @on-change="changeCategoryArr('items.' + index + '.model' + index)"></Cascader>
                  </Col>
                  <Col span="2" style="text-align: center">
                  <Icon type="ios-add-circle-outline" v-if="idx === 0" @click="addCategoryArrr(index)" style="color: #2d8cf0; font-size: 20px; cursor: pointer" />
                  <Icon type="ios-remove-circle-outline" v-else style="color: red; font-size: 20px; cursor: pointer" @click="delCategoryArrr(index, idx)" />
                  </Col>
                </Row>
              </FormItem>
              <!-- 感兴趣类别 -->

              <!-- 展馆选择 -->
              <FormItem :key="index" :label="item.label" :rules="{
                  required: item.attributes.checkOptions.require,
                  message: `${item.label}验证错误`,
                  trigger: 'change',
                }" v-if="item.tagType === 11 && item.label === '展馆选择'" :label-width="260">
                <Select v-model="formModel.items[index]['model' + index]" style="width: 300px">
                  <Option v-for="val in hallList" :value="val" :key="val">{{ val }}</Option>
                </Select>
              </FormItem>
              <!-- 展馆选择 -->
              <!-- 套件 -->
            </template>

            <FormItem :label-width="260">
              <Button type="primary" @click="handleSubmit('formModel')" size="large" style="width: 300px" :loading="loading" class="btn">下一步1</Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { validator } from "@/other/validator";
import topNav from "./components/top";
import provincial from "@/assets/js/provinces.json"; //省市区json

export default {
  name: "reg",
  data () {
    return {
      code: "",
      cateKey: 0,
      numCode: "", // 手机验证码
      timer: null,
      showCode: true,
      loading: false,
      count: "",
      codeKey: "",

      phoneIndex: -1, // 自定义表单 手机索引

      curIndex: 1,
      areaList: [], //地址数据
      categoryList: [], // 类别数据
      hallList: [], // 展馆选择
      formData: [], // 表单数据

      categoryList: [],
      formModel: {
        items: [],
      }, // 表单双向绑定数组 v-model Value
      formId: "",
      form: {
        name: "", // 姓名
        password: "", // 密码
        email: "", // 密码
        phone: "", // 手机号
        company: "", // 公司名
        jobTitle: "", // 职务
        province: "", // 省份
        city: "", // 城市
        county: "", // 区县
        category: "",
        introduction: "",
      },
    };
  },
  components: {
    topNav,
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      regOrigin: "getRegOrigin",
      user: "getUser",
      login: "getLogin",
      token: "getToken",
    }),
    isCover () {
      return this.$route.query.isCover || false;
    },
    phone () {
      return this.$route.query.phone;
    },
  },
  created () {
    this.areaList = provincial.data;
    this.init();
  },
  mounted () {
    document.getElementsByTagName("body")[0].className = "custom_body";
  },
  methods: {
    ...mapMutations({
      setLoginType: "setLoginType",
      setUser: "setUser",
      setUserId: "setUserId",
      setToken: "setToken",
      setTokenTime: "setTokenTime",
      setLogin: "setLogin",
      setExhibitionId: "setExhibitionId",
      setLoginBg: "setLoginBg",
    }),
    ...mapActions({
      updatePhonenumber: "user/updatePhonenumber",
      validatePhone: "loginModule/validatePhone",
      getMsgCode: "getMsgCode",
      validateCode: "validateCode",
      regUser: "regUser",
      regUserByVisitor: "regUserByVisitor",
      getCustomForm: "getCustomForm",
      getCategory: "getCategory",
      getHall: "getHall",
      addCustomForm: "addCustomForm",
      getExhibitionInfo: "loginModule/getExhibitionInfo",
      graphqlPost: "graphqlPost",
      graphqlPostByZXS: "graphqlPostByZXS",
      getToken: "loginModule/getToken",
    }),
    async init () {
      this.initCate();
      this.initCustomForm();
      let res = await this.getHall();
      if (res.getAllExHall) {
        res.getAllExHall.map((c) => {
          this.hallList.push(c.name);
        });
      }
    },
    async initCate () {
      let res = await this.getCategory();
      if (res && res.categories) {
        let ProCategory = JSON.parse(JSON.stringify(res.categories));
        ProCategory.map((c) => {
          c.value = c.name;
          c.label = c.name;
          if (c.childrens && c.childrens.length > 0) {
            c.children = c.childrens;
            c.children.map((v) => {
              v.value = v.name;
              v.label = v.name;
              if (v.childrens && v.ichildrens.length > 0) {
                v.children = v.childrens;
                v.children.map((z) => {
                  z.value = z.name;
                  z.label = z.name;
                });
              }
            });
          }
        });
        this.categoryList = ProCategory;
        let typeList = [];
        let array = [];
        if (this.user && this.user.inMember && this.user.inMember.category) {
          typeList = this.user.inMember.category.split(";"); 
          typeList.forEach((c) => {
            if (c.indexOf(">")) {
              c = c.split(">");
            }
            array.push(c);
          });
        }
      }
    },
    async initCustomForm () {
      let opt = {
        itemId: this.exhibitionId,
        formType: "show.form.visitor.reg",
      };
      let res = await this.getCustomForm(opt);

      let defaultItem = {
        attributes: {
          placeholder: "请输入密码",
          checkOptions: {
            require: true,
            email: false,
            phone: false,
            reg: false,
            other: "",
          },
        },
        childrens: [],
        label: "密码",
        showIndex: 2,
        tagType: 0,
      };
      this.formData = [...res.getForm.items];
      this.formId = res.getForm.id;

      this.formData.map((e) => {
        e.attributes = JSON.parse(e.attributes);
        switch (e.tagType) {
          case "INPUT":
            e.tagType = 0;
            break;
          case "TEXTAREA":
            e.tagType = 1;
            break;
          case "NUMBER":
            e.tagType = 2;
            break;
          case "SELECT":
            e.tagType = 3;
            break;
          case "RADIO":
            e.tagType = 4;
            break;
          case "CHEKBOX":
            e.tagType = 5;
            break;
          case "DATE":
            e.tagType = 6;
            break;
          case "DATEAREA":
            e.tagType = 7;
            break;
          case "IMAGE":
            e.tagType = 8;
            break;
          case "FILE":
            e.tagType = 9;
            break;
          case "TEXT":
            e.tagType = 10;
            break;
          case "COMPOUND":
            e.tagType = 11;
            break;
        }
      });
      let findEnd = this.formData.find((c) => {
        return c.tagType == 0 && c.label === "密码";
      });
      if (!findEnd) {
        this.formData.splice(1, 0, defaultItem);
      }
      this.initFromData();
    },
    setFormData () {
      console.log(this.form, this.formModel, this.user, "setset");
    },
    initFromData () {
      this.formData.map((v, i) => {
        if (v.tagType === 0 && v.label === "姓名") {
          this.formModel.items.push({
            ["model" + i]: this.user.name || "",
            index: i,
            validate: true,
            attributes: v.attributes,
            type: v.tagType,
            label: v.label,
          });
          return;
        }
        if (v.tagType === 0 && v.label === "密码") {
          this.formModel.items.push({
            ["model" + i]: "",
            index: i,
            validate: true,
            attributes: v.attributes,
            type: v.tagType,
            label: v.label,
          });
          return;
        }
        if (v.tagType === 0 && v.label === "手机") {
          this.phoneIndex = i;
          this.formModel.items.push({
            ["model" + i]: this.phone,
            index: i,
            validate: true,
            attributes: v.attributes,
            type: v.tagType,
            label: v.label,
          });
          return;
        }
        if (v.tagType === 0 && v.label === "职务") {
          this.formModel.items.push({
            ["model" + i]: this.user.jobTitle || "",
            index: i,
            validate: true,
            attributes: v.attributes,
            type: v.tagType,
            label: v.label,
          });
          return;
        }
        if (v.tagType === 0 && v.label === "公司") {
          this.formModel.items.push({
            ["model" + i]: this.user.company || "",
            index: i,
            validate: true,
            attributes: v.attributes,
            type: v.tagType,
            label: v.label,
          });
          return;
        }
        if (v.tagType === 1 && v.label === "个人简介") {
          this.formModel.items.push({
            ["model" + i]: this.user.introduction || "",
            index: i,
            validate: true,
            attributes: v.attributes,
            type: v.tagType,
            label: v.label,
          });
          return;
        }
        if (v.tagType === 11 && v.label === "城市") {
          let arr = [];
          if (this.user.province) {
            arr.push(this.user.province);
            if (this.user.city) {
              arr.push(this.user.city);
            }
            if (this.user.county) {
              arr.push(this.user.county);
            }
          }
          console.log(arr, this.user, "usera ");
          this.formModel.items.push({
            ["model" + i]: arr,
            index: i,
            validate: true,
            attributes: v.attributes,
            type: v.tagType,
            label: v.label,
          });
          return;
        }
        if (v.tagType === 11 && v.label === "感兴趣的类别") {
          this.formModel.items.push({
            ["model" + i]: [[]],
            index: i,
            validate: true,
            attributes: v.attributes,
            type: v.tagType,
            label: v.label,
          });
          return;
        }
        if (v.tagType === 11 && v.label === "展馆选择") {
          this.formModel.items.push({
            ["model" + i]: [],
            index: i,
            isShow: false,
            validate: true,
            attributes: v.attributes,
            type: v.tagType,
            label: v.label,
          });
          return;
        }
        if (v.tagType === 2) {
          this.formModel.items.push({
            ["model" + i]: 0 - 0,
            index: i,
            isShow: false,
            validate: true,
            attributes: v.attributes,
            type: v.tagType,
            label: v.label,
          });
          return;
        }
        if (v.tagType === 3) {
          // 下拉框
          v.columns = [];
          v.attributes.options.map((c) => {
            v.columns.push(c.value);
          });
          this.formModel.items.push({
            ["model" + i]: "",
            index: i,
            isShow: false,
            other: "",
            validate: false,
            attributes: v.attributes,
            type: v.tagType,
            label: v.label,
          });
          return;
        }
        if (v.tagType === 4) {
          // 单选框
          this.formModel.items.push({
            ["model" + i]: "",
            index: i,
            other: "",
            validate: false,
            attributes: v.attributes,
            type: v.tagType,
            label: v.label,
          });
          return;
        }
        if (v.tagType === 5) {
          // 多选框
          this.formModel.items.push({
            ["model" + i]: [],
            index: i,
            other: "",
            validate: false,
            attributes: v.attributes,
            type: v.tagType,
            label: v.label,
          });
          return;
        }
        if (v.tagType === 6) {
          // 日期框
          this.formModel.items.push({
            ["model" + i]: "",
            index: i,
            isShow: false,
            other: "",
            validate: false,
            attributes: v.attributes,
            type: v.tagType,
            label: v.label,
          });
          return;
        }
        if (v.tagType === 7) {
          // 日期区间
          this.formModel.items.push({
            ["model" + i]: { start: "", end: "" },
            index: i,
            validate: false,
            attributes: v.attributes,
            isShow: false,
            type: v.tagType,
            labelParent: v.label,
            parentId: v.childrens[0].parentId,
            label: [v.childrens[0].label, v.childrens[1].label],
          });
          return;
        }
        if (v.tagType === 10) {
          // 文字说明
          this.formModel.items.push({
            ["model" + i]: [],
            index: i,
            validate: true,
            attributes: v.attributes,
            type: v.tagType,
            label: v.label,
          });
          return;
        }
        this.formModel.items.push({
          ["model" + i]: "",
          index: i,
          validate: false,
          attributes: v.attributes,
          type: v.tagType,
          label: v.label,
        });
      });
      console.log(this.formModel, "formModel");
      console.log(this.formData, "this.formData");
    },

    validForm (name) {
      // 验证表单
      return new Promise((resolve, reject) => {
        this.$refs[name].validate((valid) => {
          resolve(valid);
        });
      });
    },

    async handleSubmit (name) {
      let valid = await this.validForm(name); // 验证表单
      if (!valid) return;
      if (!this.validFormByCus()) return; // 验证失败

      if (!this.phone) {
        // 默认账户没有手机号 需走一遍验证手机号的流程
        console.log(this.formModel.items);
        console.log(this.phoneIndex);
        let txt = "";
        let setPhone = this.formModel.items[this.phoneIndex]["model" + this.phoneIndex];
        if (!this.codeKey) {
          this.$Message.warning("请获取验证码!");
          return false;
        }

        let valid = await this.validateCode({
          key: this.codeKey,
          code: this.numCode,
        });
        if (!valid) {
          this.$Message.error("验证未通过!");
          return;
        }

        let res = await this.validatePhone(setPhone);
        if (res && res.data) {
          this.$Message.error({
            content: `手机号（${setPhone}）已绑定其他账号`,
            duration: 2,
          });
          return;
        }
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 5000);

        const req = {
          memberId: this.user.inMember.memberId,
          phonenNumber: setPhone,
        };

        let bool = await this.updatePhonenumber(req).catch((err) => {
          this.loading = false;
        });
        if (!bool.result) {
          // 修改手机失败
          this.$Message.error({
            content: `绑定手机号失败！`,
            duration: 2,
          });
          return;
        }
      }
      this.reg();
    },

    validFormByCus () {
      // 根据类型验证表单
      let valid = true;
      let errorTxt = "";
      this.formModel.items.map((v, i) => {
        // 批量验证 表单选项
        if (!valid) return;
        switch (v.type) {
          case 0: // 单行文本
          case 1: // 多行文字
            valid = this.setRegRule(v["model" + i], v.attributes.checkOptions);
            if (!valid) {
              errorTxt = `${v.label},验证错误`;
            }
            break;
          case 3: // 下拉选择框
            valid = this.checkFormItem(v.attributes, v["model" + i]);
            if (!valid) {
              errorTxt = `${v.label}选项为空`;
            }
            break;
          case 4: // 单选
            valid = this.checkFormItem(v.attributes, v["model" + i]);
            if (!valid) {
              errorTxt = `${v.label}选项为空`;
            }
            break;
          case 5: // 多选
            valid = this.checkFormItem(v.attributes, v["model" + i]);
            if (!valid) {
              errorTxt = `${v.label}最少选择一项`;
            }
            break;
        }
      });
      if (!valid) {
        this.$Message.error(errorTxt);
      }
      return valid;
    },
    checkFormItem (v, control) {
      // 检查单个表单 必填等
      const checkOpt = v.checkOptions;
      let valid = true;
      if (checkOpt && checkOpt.require && String(control) === "") {
        // 验证必填
        valid = false;
      }
      return valid;
    },
    setRegRule (val, rules) {
      // 根据验证选项返回验证规则
      const ruleArr = Object.keys(rules);
      let ruleStr = true;
      ruleArr.forEach((c) => {
        if (c === "phone" && rules[c]) {
          ruleStr = /^1[3456789]\d{9}$/.test(val);
        }
        if (c === "email" && rules[c] && rules["require"]) {
          ruleStr = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(val);
        }
        if (c === "idCard" && rules[c]) {
          // 身份证
          ruleStr = /(^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$)/.test(val);
        }
        if (c === "other" && rules[c] !== "") {
          let reg = rules[c].replace(/^(\s|\/)+|(\s|\/)+$/g, "");
          let regExp = new RegExp(reg);
          ruleStr = regExp.test(val);
        }
      });
      return ruleStr;
    },
    async reg () {
      const formFill = await this.transformSumbitData();
      let body = {
        ...this.form,
        exhibitionId: this.exhibitionId,
        isCover: this.isCover ? true : false,
        originType: this.regOrigin ? this.regOrigin.originType : "",
        originId: this.regOrigin ? this.regOrigin.originId : "",
        originRemark: this.regOrigin ? this.regOrigin.originRemark : "",
        originLang: "cn",
        formWrite: {
          // 自定义表单数据
          itemId: this.exhibitionId,
          formId: this.formId,
          userId: "defalut",
          items: formFill,
        },
      };
      let res = false;
      if (this.user && this.user.userId) {
        let opt = {
          ...this.form,
          inMemberId: this.user.inMember.id,
          exhibitorId: this.exhibitionId,
          originType: this.regOrigin ? this.regOrigin.originType : "",
          originId: this.regOrigin ? this.regOrigin.originId : "",
          originRemark: this.regOrigin ? this.regOrigin.originRemark : "",
          originLang: "cn",
        };
        let bool = await this.regUserByVisitor(opt);
        if (bool.result) {
          const reqBody = {
            itemId: this.exhibitionId,
            formId: this.formId,
            userId: this.user.userId,
            items: formFill,
          };
          res = await this.addCustomForm(reqBody);
        } else {
          this.$Message.error(bool.message + "dierge");
        }
      } else {
        res = await this.regUser(body);
      }

      if (res.result) {
        if (res.data) {
          this.code = res.data.code;
        }
        this.regFinish();
      } else {
        this.$Message.error(res.message);
      }
    },

    async transformSumbitData () {
      // 转换数据为提交的表单
      return new Promise((resolve, reject) => {
        const valArr = [];
        this.formModel.items.forEach((v, i) => {
          if (v.type === 10) return;
          let obj = {};
          let objArr = [];
          if (v.type === 7) {
            // 日期区间
            const dateRange = [v["model" + i].start, v["model" + i].end];
            valArr.push({
              label: v.labelParent,
              Value: "",
              tagType: v.type,
              childrens: [
                { label: v.label[0], Value: v["model" + i].start },
                { label: v.label[1], Value: v["model" + i].end },
              ],
            });
            return;
          }
          if (v.type === 1 && v.label === "个人简介") {
            valArr.push({
              label: v.label,
              Value: v["model" + i],
              tagType: v.type,
            });
            return;
          }
          if (v.type === 2) {
            // 数字类型需要转字符串
            valArr.push({
              label: v.label,
              Value: v["model" + i] + "",
              tagType: v.type,
            });
            return;
          }
          if (v.type === 5) {
            valArr.push({
              label: v.label,
              Value: JSON.stringify(v["model" + i]),
              tagType: v.type,
            });
            return;
          }
          if (v.type === 11 && v.label === "城市") {
            valArr.push({
              label: "城市",
              Value: v["model" + i],
              tagType: v.type,
            });
            return;
          }
          if (v.type === 11 && v.label === "感兴趣的类别") {
            valArr.push({
              label: v.label,
              Value: v["model" + i],
              tagType: v.type,
            });
            return;
          }
          obj = { label: v.label, Value: v["model" + i], tagType: v.type };
          valArr.push(obj);
        });
        this.fillDefaultFormValue(valArr);
        valArr.map((v) => {
          if (v.tagType === 11 && v.label === "城市") {
            v.Value = v.Value.join("/");
          }
        });
        resolve(valArr);
      });
    },
    fillDefaultFormValue (Data) {
      // 填充观众注册默认提交数据
      if (!Data) return;
      Data.forEach((c) => {
        switch (c.label) {
          case "姓名":
            this.form.name = c.Value;
            break;
          case "密码":
            this.form.password = c.Value;
            break;
          case "手机":
          case "手机号":
            this.form.phone = c.Value;
            break;
          case "公司":
            this.form.company = c.Value;
            break;
          case "职务":
            this.form.jobTitle = c.Value;
            break;
          case "邮箱":
          case "email":
          case "E-mail":
            this.form.email = c.Value;
            break;
          case "城市":
            this.form.province = c.Value[0] || "";
            this.form.city = c.Value[1] || "";
            this.form.county = c.Value[2] || "";
            break;
          case "感兴趣的类别":
            let category = [];
            if (c.Value.length > 0) {
              c.Value.forEach((c) => {
                if (c.length > 1) {
                  c = c.join(">");
                } else {
                  c = c[0];
                }
                category.push(c);
              });
              c.Value = category.join(";");
            }
            this.form.category = c.Value;
            break;
          case "个人简介":
            this.form.introduction = c.Value;
            break;
        }
      });
      console.log(this.form, "注册的表单数据");
    },

    async getCaptcha (phone) {
      if (!phone) {
        let txt = "";
        if (!txt) {
          txt = "请输入手机号码";
        } else if (/^[1][0-9]{10}$/.test(phone)) {
          txt = "";
        } else {
          txt = "请输入正确的手机号码";
        }
        if (txt) {
          this.$Message.error({ content: txt, duration: 2 });
        }
        return;
      }
      let res = await this.validatePhone(phone);
      if (res && res.data) {
        this.$Message.error({
          content: `手机号（${phone}）已绑定其他账号`,
          duration: 2,
        });
        return;
      } else {
        this.downTime(phone);
      }
    },
    async downTime (phone) {
      const valid = await this.getMsgCode(phone);
      if (valid && valid.result) {
        this.codeKey = valid.data.key;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.showCode = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.showCode = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },

    addCategoryArrr (index) {
      if (this.formModel.items[index]["model" + index].length >= 5) {
        this.$Message.warning(`最多添加5个展品类别`);
        return;
      }
      this.formModel.items[index]["model" + index].push([]);
      this.cateKey += 1;
    },
    delCategoryArrr (index, idx) {
      console.log(index, idx);
      this.cateKey += 1;
      this.formModel.items[index]["model" + index].splice(idx, 1);
      this.$nextTick(() => {
        this.formModel.items[index]["model" + index] = JSON.parse(JSON.stringify(this.formModel.items[index]["model" + index]));
      });
    },
    changeCategoryArr (cur) {
      setTimeout((c) => {
        this.$refs["formModel"].validateField(cur);
      }, 400);
    },

    async regFinish () {
      if (this.login) {
        this.$router.push(`/regFinish/${this.exhibitionId}${this.code ? "?code=" + this.code : ""}`);
        return;
      }
      let phone = this.formModel.items[this.phoneIndex]["model" + this.phoneIndex];
      let orgin = location.host.split(".").reverse()[1];
      let clientId = orgin === "zhanshangxiu" ? "synair-showonline-js" : `synair-${orgin}-js`;
      let opt = {
        client_id: clientId,
        client_secret: "synair-showonline-js",
        grant_type: "phone_number",
        scope: "openid profile showonline-api bmm-api phone",
        phone_number: phone,
      };
      let tokenData = await this.getToken(opt);
      if (tokenData) {
        this.setToken(tokenData.access_token);
        this.setTokenTime(tokenData.expires_in * 1000 + new Date().getTime());
        this.setExhibitionId(this.exhibitionId);
        await this.getExhibitionInfo(this.exhibitionId);
        this.setLoginType(1);
        this.getUser();
      }
    },

    async getUser () {
      let query = `
        query{
          memberQuery{
            current{
              address
              avatarUrl
              balance
              birthday
              businessCard
              city
              company
              country
              county
              createAt
              deleteAt
              department
              email
              followCount
              id
              inMember(exhibitionId:"${this.exhibitionId}"){ 
                business
                category
                id 
                memberId
                originId
                originLang
                originRemark
                isBlacklist
                originType
                regAt
                sayCount
                sayUserCount
                userId
                userRole
                exhibitorId
                companyUsers{
                  id
                  isAppointment
                }
              }
              introduction
              isDeleted
              jobTitle
              name
              nickName
              phone
              phoneConfirm
              province
              talkCount
              talkSuccessedCount
              title
              updateAt
              userId
            }
          } 
        }
      `;
      let opt = {
        opt: {
          query: query,
        },
        token: this.token,
      };
      let data = await this.graphqlPost(opt);
      if (data.data && data.data.memberQuery && data.data.memberQuery.current) {
        this.setLogin(true);
        this.setUser(data.data.memberQuery.current);
        this.setUserId(data.data.memberQuery.current.userId);
        this.$router.push(`/regFinish/${this.exhibitionId}${this.code ? "?code=" + this.code : ""}`);
      }
    },
  },
};
</script>
<style lang="scss">
.custom_body {
  overflow: auto !important;
  overflow-x: hidden !important;
}
</style>
<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.reg {
  &-header {
    box-shadow: 0px 2px 3px 0px rgba(101, 101, 101, 0.1);
  }
  &-main {
    width: 1200px;
    margin: 50px auto;
    ::v-deep .m-steps {
      width: 710px;
      margin: 0 auto;
      .ivu-steps-item {
        .ivu-steps-head {
          background: transparent;
        }
      }
    }
  }
  .F {
    display: flex;
    flex-direction: column;
    width: 800px;
    height: 380px;
    position: relative;
    margin: 0 auto;
    &-1 {
      margin-top: 34px;
      margin-bottom: 0;
      &.phone_code {
        ::v-deep {
          .ivu-form-item-content {
            display: flex;
            .ivu-btn {
              margin-left: 10px;
            }
          }
        }
      }
    }
    .F-2 {
      width: 100%;
      height: 15px;
      line-height: 15px;
      display: flex;
      justify-content: space-between;
      margin: 24px 0;
      .F-2-text {
        width: 48px;
        height: 13px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
      }
    }
    .wx-btn {
      margin-top: 20px;
    }
    .code {
      width: 70px;
      height: 26px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      cursor: pointer;
    }
    .btn {
      margin: 34px auto 0;
    }
  }
}
.admin-add {
  margin-top: 34px;
}
.service-code {
  position: relative;
  width: 300px;
  margin-top: 20px;
  .code-btn1 {
    position: absolute;
    top: 4px;
    right: 7px;
    height: 28px;
    padding: 0;
    @include font_color(#1890ff);
  }
  .code-btn2 {
    position: absolute;
    top: 4px;
    right: 7px;
    height: 28px;
    padding: 0;
  }
}
</style>
